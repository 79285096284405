import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { encode } from 'utils/encode';
import s from './PricingForm.scss';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Loader from '../../assets/svg/dot-loader.svg';
import { CheckMark } from '../check-mark/CheckMark';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input';

interface PricingFormProps {
  onSubmit: () => void;
}
export const PricingForm = ({ onSubmit }: PricingFormProps) => {
  const [loading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [phoneVal, setPhoneVal] = useState('');

  const pricingFormik = useFormik({
    initialValues: {
      email: '',
      name: '',
      phone: '',
      sendPromo: true,
      firstName: '',
    },
    validationSchema: Yup.object<any>({
      email: Yup.string().email().required(),
      name: Yup.string().required(),
      phone: Yup.string().required(),
      sendPromo: Yup.boolean(),
      firstName: Yup.string(),
    }),
    onSubmit: () => {},
  });

  const handleSubmit = useMemo(
    () => async (e: React.SyntheticEvent) => {
      e.preventDefault();
      setIsLoading(true);
      trackCustomEvent({
        category: 'Marketing',
        action: 'Click',
        label: 'View Pricing',
      });
      try {
        if (!pricingFormik.isValid || !isValidPhoneNumber(pricingFormik.values.phone)) {
          throw new Error();
        }
        pricingFormik.setFieldValue('firstName', pricingFormik.values.name.split(' ')?.[0]);
        console.log(pricingFormik.values.firstName, '>>>', pricingFormik.values.name.split(' '));
        if (pricingFormik.values.sendPromo) {
          await fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': 'pricing',
              email: pricingFormik.values.email,
              name: pricingFormik.values.name,
              phone: pricingFormik.values.phone,
              firstName: pricingFormik.values.name.split(' ')?.[0],
              sendPromo: pricingFormik.values.sendPromo,
            }),
          });
        }
        await fetch('https://ruadeseo.herokuapp.com', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: `mutation AddInterest($interestInput: InterestInput!){addInterest(interestInput: $interestInput) {success}}`,
            variables: {
              interestInput: {
                email: pricingFormik.values.email,
                name: pricingFormik.values.name,
                phone: pricingFormik.values.phone,
              },
            },
          }),
        });
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(true);
        }, 500);
        pricingFormik.resetForm();
        setPhoneVal('');
        setTimeout(() => {
          onSubmit();
        }, 2500);
      } catch (e) {
        console.log(e);
        pricingFormik.setFieldError('email', `hmmm let's try that again`);
        setIsLoading(false);
      }
    },
    [pricingFormik],
  );

  const getButtonContent = () => {
    if (loading) {
      return <Loader />;
    } else if (success) {
      return (
        <div className="flex_center_row">
          <span className="margin_right_8">Thanks!</span> {<CheckMark />}
        </div>
      );
    } else {
      return 'View pricing';
    }
  };

  const handlePhoneChange = (phoneNumber: string) => {
    if (!phoneNumber?.length) {
      return;
    }
    pricingFormik.setFieldValue('phone', phoneNumber);
    setPhoneVal(phoneNumber);
  };

  return (
    <form data-netlify="true" name="pricing" method="post" className={classNames(s.form)}>
      <input type="hidden" name="form-name" value="pricing" />
      <input
        {...pricingFormik.getFieldProps('name')}
        type="text"
        id="name-input"
        className={classNames(s.emailInput, 'brandon_15', 'margin_bottom_16')}
        placeholder="Name"
      />
      <input
        {...pricingFormik.getFieldProps('firstName')}
        type="text"
        id="name-input"
        className="hide"
        placeholder="firstName"
      />
      <Input
        country="US"
        type="text"
        id="name-input"
        name="phone"
        placeholder="(555) 555-555"
        value={phoneVal}
        onChange={handlePhoneChange}
        className={classNames(s.emailInput, 'margin_bottom_16', 'brandon_15')}
      />
      <input
        {...pricingFormik.getFieldProps('email')}
        type="email"
        id="email-input"
        className={classNames(s.emailInput, 'brandon_15', 'margin_bottom_16')}
        placeholder="Email"
      />
      <div className="flex_center_row margin_bottom_32">
        <p className="brannan_18 margin_right_8">
          I'd like to receive a code for $50 off a future trip
        </p>
        <input
          {...pricingFormik.getFieldProps('sendPromo')}
          type="checkbox"
          defaultChecked={true}
          id="number-input"
          className={classNames(s.checkbox, 'brandon_15')}
        />
      </div>
      <button
        className={classNames('button', success ? s.success : undefined)}
        style={{ width: '100%' }}
        disabled={
          !pricingFormik.isValid ||
          !pricingFormik.dirty ||
          loading ||
          !isValidPhoneNumber(pricingFormik.values.phone)
        }
        onClick={handleSubmit}
      >
        {getButtonContent()}
      </button>
    </form>
  );
};
