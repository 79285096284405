import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import s from '../styles/wine-tour.scss';
import Logo from '../assets/svg/logo.svg';
import classNames from 'classnames';
import { reviews } from 'utils/copy/yelp';
import { YelpCard, Photo6x6 } from 'components';
import { photoGridCopy } from 'utils/copy/home';
import { topRight, topLeft, bottomMiddle, fincaViewWide } from '../assets/photo-urls';
import { Footer } from 'components/footer/Footer';
import { Overlay } from 'components/overlay/Overlay';

import Google from 'assets/svg/google.svg';
import Yelp from 'assets/svg/yelp.svg';
import Instagram from 'assets/svg/instagram.svg';
import Facebook from 'assets/svg/facebook.svg';
import TripAdvisor from 'assets/svg/tripadvisor.svg';
import { PricingForm } from 'components/pricing-form';
import { withPrefix } from 'gatsby';

// tslint:disable no-default-export
export default () => {
  const [overylayOpen, setOverlayOpen] = useState(false);

  const viewPricing = () => {
    setOverlayOpen(true);
  };

  const onSubmit = () => {
    // redirect
    setOverlayOpen(false);
    const redirectUrl = `${window.location.origin}/valle-de-guadalupe-public-tours#book-wine-tour`;
    window.location.replace(redirectUrl);
  };

  return (
    <>
      <Helmet title="Valle de Guadalupe Wine Tours">
        <link rel="canonical" href="https://www.valledeguadalupewinetours.com/wine-tour" />
        {process.env.NODE_ENV === 'production' ? (
          <script src={withPrefix('fullstory.js')} type="text/javascript" />
        ) : undefined}
      </Helmet>
      <nav className={s.nav}>
        <Logo />
        <div className="hideMobile">
          <p className="brandon_18 margin_bottom_4">Questions? Give us a call or text!</p>
          <h1 className="garamond_30 margin_bottom_16">+1(858) 704-6525</h1>
        </div>
      </nav>

      <div className={s.photoWrapper}>
        <div
          className={classNames(s.half, s.left)}
          style={{ backgroundImage: `url(${fincaViewWide})` }}
        ></div>
        <div className={classNames(s.half, s.right)}>
          <div className="showMobile margin_bottom_32">
            <p className="brandon_18 margin_bottom_4">Questions? Give us a call or text!</p>
            <h1 className="garamond_30 margin_bottom_16">+1(858) 704-6525</h1>
          </div>
          <h1 className="garamond_61 margin_bottom_4">Valle de Guadalupe Wine Tours</h1>
          <p className="brandon_12_bold margin_bottom_16">━ by Ruadeseo</p>
          <h2 className="brandon_18 margin_bottom_24">
            Interested in discovering Mexico's Wine Country? We offer all-inclusive trips to Valle
            de Guadalupe from San Diego. We handle agenda planning, reservations, payment, and
            transportation. All you need to do is sit back, relax and enjoy your trip in Mexico's
            beautiful wine country.
          </h2>
          <button onClick={viewPricing} className="button_pink">
            View pricing and agendas
          </button>
        </div>
      </div>

      <section className={s.reviewSection}>
        <div className={s.reviews}>
          {reviews.map((review) => {
            return <YelpCard key={review.id} review={review} />;
          })}
        </div>
      </section>
      {/* <section className="padding_64_top">
        <div className={classNames('flex_center_row', s.founderRow)}>
          <div style={{ maxWidth: '400px' }} className={s.meetFounder}>
            <h1 className="garamond_61 margin_bottom_24">Meet our Co-Founder, Ryan</h1>
            <button onClick={viewPricing} className="button_pink">
              View pricing and agendas
            </button>
          </div>
          <div className={s.half}>
            <div className={s.video}>
              <div
                className={s.videoMobile}
                style={{ padding: '56.25% 0 0 0', position: 'relative' }}
              >
                <iframe
                  src="https://player.vimeo.com/video/699611708?h=34f3fb6f79&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen={true}
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="Ruadeseo FAQ - Valle de Guadalupe Wine Tours"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className={s.section_3}>
        <Photo6x6
          photoTextInfo={{
            topLeftHeading: photoGridCopy.topLeftHeading,
            topLeftDescription: photoGridCopy.topLeftDescription,
            topRightHeading: photoGridCopy.topRightHeading,
            topRightDescription: photoGridCopy.topRightDescription,
            bottomMiddleHeading: photoGridCopy.bottomMiddleHeading,
            bottomMiddleDescription: photoGridCopy.bottomMiddleDescription,
            topRight,
            topLeft,
            bottomMiddle,
          }}
        />
      </section>
      <Footer
        logo={<div>ruadeseo</div>}
        social={[
          { icon: <Google />, to: 'https://g.page/r/CVUTH963p9IBEAE' },
          { icon: <Yelp />, to: 'https://www.yelp.com/biz/ruadeseo-san-diego-3' },
          { icon: <Instagram />, to: 'https://www.instagram.com/ruadeseo' },
          { icon: <Facebook />, to: 'https://www.facebook.com/Ruadeseo-102230698813036' },
          {
            icon: <TripAdvisor />,
            to:
              'https://www.tripadvisor.com/Attraction_Review-g60750-d23461328-Reviews-Ruadeseo-San_Diego_California.html',
          },
        ]}
      />
      <Overlay overylayOpen={overylayOpen} setOverlayOpen={setOverlayOpen}>
        <h1 className="garamond_61 margin_bottom_16">Wine Tour Info</h1>
        <p className="brandon_18 margin_bottom_24">
          Enter your details to view pricing and trip agendas.
        </p>
        <PricingForm onSubmit={onSubmit} />
      </Overlay>
    </>
  );
};
